/*eslint-disable */
export default {
  name: 'payment-success',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
    if(this.$route.query.token){
      let data = {
        token:this.$route.query.token
      }
      this.$store.dispatch('getAppointmentIdFromTransactionFunc', data).then((res) => {
        let appointmentID = res.data.appointment_id
        let data = {
          payment_pending:false
        }
        this.$store.dispatch('updateAppointmentDetailsApiFun', {data:{data:data,id:appointmentID}})
      })
    }
  },
  methods: {
  }
}


